<!--
 * @Author: your name
 * @Date: 2021-05-08 18:04:46
 * @LastEditTime: 2021-05-18 22:30:42
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\components\nomsg\index.vue
-->
<template>
  <div>
    <van-empty
      :image="imges"
      :description="msg?msg:'当前候选人尚未设置技能'"
    />
  </div>
</template>
<script>
export default {
  props:{
    msg:String
  },
    data(){
        return{
            imges:require("../../assets/nomsg4.png")
        }
    }
};
</script>
<style scoped>
.van-empty >>> .van-empty__image {
  width: 2rem;
  height: 1.8rem;
}
</style>
