<!--
 * @Author: your name
 * @Date: 2021-04-25 17:50:52
 * @LastEditTime: 2021-05-20 23:00:32
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\evalsee\index.vue
-->
<template>
  <div class="allmsgclass">
    <navigation></navigation>
    <div class="msgclass">
      <div class="qycla qycla4">
        <div class="pp1">评价单位</div>
        <div class="qycla3">
          <img src="../../assets/time.png" alt="" />
          <div>{{ gspjmsg.add_time }}</div>
        </div>
      </div>
      <div class="pp_1">
        <div>评价公司：</div>
        <div>{{ $route.query.company }}</div>
      </div>
      <div class="pp_1">
        <div>评价人：</div>
        <div>{{ gspjmsg.username }}</div>
        <span style="margin: 0 0.2rem">|</span>
        <div>职务：</div>
        <div>{{ gspjmsg.positions }}</div>
      </div>
      <div class="henggang"></div>
      <div class="qycla qycla2">
        <div class="pp1">评价内容</div>
        <div class="kaiqiclass" @click="openclomethmain(gspjmsg)">
          {{
            gspjmsg.is_open == "1"
              ? "隐藏"
              : gspjmsg.is_open == "2"
              ? "开启"
              : "获取失败"
          }}
        </div>
      </div>

      <div class="pp2">
        {{
          gspjmsg.is_open == 1
            ? gspjmsg.content
            : "****************************************"
        }}
      </div>
      <div class="pp3">回评</div>
      <div v-for="(item, index) in ygarray" v-bind:key="index" class="ping">
        <div class="yuanimg">
          <img
            v-if="(item.sex + '').indexOf('男') != -1"
            src="../../assets/user_1.png"
            alt=""
          />
          <img
            v-else-if="(item.sex + '').indexOf('女') != -1"
            src="../../assets/user_2.png"
            alt=""
          />
          <img v-else src="../../assets/user_1.png" alt="" />
        </div>
        <div class="pingjc">
          <div class="nameclass">
            <div>{{ item.name }}</div>
            <div
              class="kaiqiclass"
              v-if="item.reply_status == 2"
              @click="openclometh(item)"
            >
              {{
                item.comment_set == "1"
                  ? "隐藏"
                  : item.comment_set == "2"
                  ? "开启"
                  : "获取失败"
              }}
            </div>
          </div>
          <div>
            {{
              item.comment_set == "2"
                ? "****************************************"
                : item.content
            }}
          </div>
          <div>评价时间：{{ item.add_time }}</div>
        </div>
      </div>
    </div>

    <div v-show="isloading == true" class="loadclass">
      <van-loading type="spinner">加载中...</van-loading>
    </div>
    <!-- <van-empty
      v-show="ishavemsg == false"
      :image="imges"
      description="- 空空如也，一条数据也没有 -"
    /> -->
    <nomsg v-show="ishavemsg == false" msg=" - 您还没有任何回评 - "></nomsg>

    <div v-show="withoutmsg == true" @click="clickload" class="allmore">
      <div v-show="open != true">
        <span class="iconclass">
          <van-icon name="arrow-down" />
        </span>
        <span>加载更多</span>
      </div>
      <div v-show="open == true">
        <van-loading type="spinner" color="#1989fa" />
      </div>
    </div>

    <div class="nomsgclass"></div>
    <div v-if="$route.query.iscom != 'true'" class="hpclass">
      <div @click="toevaluation">我要回评</div>
    </div>
  </div>
</template>
<script>
import nomsg from "../../components/nomsg/index";
export default {
  components: {
    nomsg,
  },
  data() {
    return {
      gspjmsg: "", //公司评价
      ygarray: [], //员工回评
      pageno: 1,
      isloading: true,
      ishavemsg: true,
      withoutmsg: false,
      open: false,
      imges: require("../../assets/nomsg.png"),
      allmsglength: 0, //所有数据长度
    };
  },
  created() {
    this.getmsg();
  },
  methods: {
    //开启隐藏消息(主消息)
    openclomethmain(item) {
       
      this.$http
        .post("/firm/v1/profile/shieldMessage", {
          reqType: "resume",
          type: item.is_open == 1 ? "2" : "1",
          evaluationId: item.id,
          messageType: 1,
          
        })
        .then((res) => {
           
          this.$toast(`${item.is_open == 1 ? "隐藏成功" : "开启成功"}`);
          this.ygarray = [];
          this.pageno = 1;
          this.getmsg();
        });
    },
    //开启隐藏消息
    openclometh(item) {
       
      this.$http
        .post("/firm/v1/profile/shieldMessage", {
          reqType: "resume",
          type: item.comment_set == 1 ? "2" : "1",
          evaluationId: this.gspjmsg.id,
          messageType: 2,
          replyCommentId: item.id,
        })
        .then((res) => {
           
          this.$toast(`${item.comment_set == 1 ? "隐藏成功" : "开启成功"}`);
          this.ygarray = [];
          this.pageno = 1;
          this.getmsg();
        });
    },
    //加载更多
    clickload() {
      this.open = true;
      this.pageno++;
      this.getmsg();
    },
    //加载数据
    getmsg() {
      this.isloading = true;
      this.$http
        .post("/firm/v1/profile/commentDetail", {
          reqType: "profile",
          id: atob(this.$route.query.id),
          pageno: this.pageno,
          limit: 15,
        })
        .then((res) => {
           
          this.allmsglength = JSON.parse(res.data).data.myCommentCount;
          this.gspjmsg = JSON.parse(res.data).data.commentDetail;
          let thearray = JSON.parse(res.data).data.myComment;
          this.open = false;
          if (this.allmsglength < 1) {
            this.ishavemsg = false;
          } else {
            if (
              this.allmsglength / 15 == this.pageno ||
              this.allmsglength / 15 > this.pageno
            ) {
              this.withoutmsg = true;
            } else {
              this.withoutmsg = false;
            }
          }
          this.isloading = false;
          thearray.forEach((e) => {
            this.ygarray.push(e);
          });
        });
    },
    //回评
    toevaluation() {
      this.$router.push({
        path: "evaluation",
        query: {
          ishui: true,
          id: this.gspjmsg.id,
          phone: this.$route.query.phone,
        },
      });
    },
  },
};
</script>
<style scoped>
.allmsgclass .qycla4{
  padding-top: .2rem;
}
.qycla3{
  display: flex;
  align-items: center;
  margin-left: .3rem;
}
.qycla3 img{
  width: .25rem;
  margin-right: .12rem;
}
.qycla {
  display: flex;
  align-items: center;
  padding-top: 0.46rem;
  margin-bottom: 0.28rem;
}
.qycla2{
justify-content: space-between;
}
.pp1 {
  font-size: 0.42rem;
  line-height: 0.48rem;
  color: #333333;

  font-weight: bold;
}
.henggang {
  height: 1px;
  background: #bfbfbf;
  margin-top: 0.48rem;
}
.pp_1 {
  display: flex;
  align-items: center;
  margin-bottom: 0.2rem;
  color: #706f6f;
}
.pp5 {
  display: flex;
  align-items: center;
}
.pp5 > :last-child {
  padding-top: 0.2rem;
  color: #929292;
  margin-left: 0.12rem;
}
.pp5 img {
  width: 0.3rem;
  padding-top: 0.2rem;
  margin-left: 0.3rem;
}
.nameclass {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.kaiqiclass {
  color: #c9c7c6;
  font-weight: 500;
  border: 1px solid #ece3dd;
  font-size: 0.25rem;
  padding: 0.02rem 0.15rem;
  border-radius: 0.1rem;
}
.van-empty >>> .van-empty__image {
  width: 3rem;
  height: 2.7rem;
}
.loadclass {
  text-align: center;
  padding-top: 2rem;
}
.allmore {
  width: 80%;
  background-color: #ffffff;
  margin: 0.3rem 0 0.1rem 50%;
  transform: translate(-50%);
  text-align: center;
  position: relative;
  padding: 0.3rem 0.2rem;
  margin-bottom: 1rem;
  font-size: 0.35rem;
  border-radius: 2rem;
  color: #202124;
  border: 1px solid #6f6f6f;
}
.iconclass {
  position: absolute;
  left: 0.45rem;
  top: 50%;
  transform: translateY(-50%);
}
.nomsgclass {
  height: 1.5rem;
}
.hpclass {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0.3rem 0;
  background-color: #ffffff;
}
.hpclass > div {
  border: 2px solid #ff5f19;
  border-radius: 0.16rem;
  padding: 0.28rem;
  font-size: 0.3rem;
  line-height: 0.42rem;
  color: #ff5f19;
  text-align: center;
  width: 80%;
  margin-left: 50%;
  transform: translateX(-50%);
}
.ping {
  display: flex;
  margin-bottom: 0.6rem;
}
.pingjc {
  width: 100%;
}
.pingjc > :first-child {
  font-size: 0.28rem;
  line-height: 0.4rem;
  color: black;
  font-weight: bold;
  font-size: 0.3rem;
}
.pingjc > :nth-child(2),
.pingjc > :nth-child(3) {
  font-size: 0.24rem;
  line-height: 0.33rem;
  color: #6f6f6f;
  margin-top: 0.18rem;
  word-wrap: break-word;
}
.yuanimg {
  min-width: 1rem;
  max-width: 1rem;
  height: 1rem;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 0.26rem;
}
.yuanimg img {
  width: 100%;
  height: 100%;
}

.pp2 {
  font-size: 0.28rem;
  line-height: 0.4rem;
  color: #6f6f6f;
  padding-bottom: 0.46rem;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #bfbfbf;
}
.pp3 {
  font-size: 0.42rem;
  line-height: 0.48rem;
  color: #333333;
  font-weight: bold;
  margin: 0.76rem 0 0.53rem 0;
}
.msgclass {
  margin: 0.28rem 0.48rem;
  margin-top: 0;
}
.allmsgclass {
  background-color: #ffffff;
  min-height: 100%;
}
</style>
